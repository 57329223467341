.banner-content {
	text-align: center;
	max-width: 650px;
	margin: 0 auto;
	position: relative;
	z-index: 9;
	.title {
		font-size: 36px;
		margin-bottom: 10px;
	}
	.logo {
		margin-bottom: 20px;
	}
	padding: 140px 0 40px;
	.text {
		max-width: 500px;
		margin: 0 auto;
		font-size: 18px;
	}
	@include breakpoint(max-md) {
		padding-top: 100px;
	}
	@include breakpoint(max-sm) {
		padding-top: 50px;
		.title {
			font-size: 28px;
		}
	}
}
.banner-shape-1 {
	position: absolute;
	left: 0;
	top: -130px;
	@include breakpoint(max-lg) {
		top: 0;
		max-height: calc(100% - 100px);
		width: unset;
	}
}
.banner-shape-2 {
	right: 0;
	position: absolute;
	bottom: 193px;
	@include breakpoint(max-lg) {
		top: 0;
		bottom: unset;
		svg {
			height: unset;
			width: 130px;
		}
		right: -40px;
	}
	@include breakpoint(max-sm) {
		display: none;
	}
}
.banner-img {
	width: 100%;
	height: unset;
	position: relative;
	z-index: 9;
}
.banner-section {
	padding-bottom: 57px;
	@include breakpoint(max-lg) {
		overflow: hidden;
	}
}
.cloud {
	position: absolute;
	width: 82px;
	left: 0;
	opacity: 0.8;
}
.cloud-1 {
	width: 150px;
	left: 1500px;
	bottom: 250px;
}
.cloud-2 {
	bottom: 20%;
}
.cloud-4 {
	bottom: 165px;
	left: 200px;
}
.cloud-3 {
	width: 150px;
	left: 500px;
	bottom: 193px;
}
.cloud-5 {
	width: 120px;
	left: 1300px;
	bottom: 180px;
}
.cloud-6 {
	left: 800px;
	bottom: 85px;
}
.cloud-7 {
	left: 1050px;
	bottom: 205px;
	width: 130px;
}
.cloud-img {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 50px);
	overflow: hidden;
	opacity: 0.5;
	@include breakpoint(max-xl) {
		.cloud-4 {
			bottom: 125px;
		}
		.cloud-7 {
			bottom: 135px;
		}
		.cloud-3 {
			bottom: 145px;
		}
		.cloud-1 {
			bottom: 135px;
		}
	}
	.cloud {
		animation: cloudAnime 25s alternate infinite;
	}
	@include breakpoint(max-md) {
		.cloud {
			bottom: 70px;
			&:nth-child(even) {
				width: 40px;
			}
			&:nth-child(odd) {
				width: 50px;
			}
		}
	}
}

@keyframes cloudAnime {
	0% {
		transform: translate(-1220px);
	}
	100% {
		transform: translate(920px);
	}
}
