$bootstrap-sass-asset-helper: false !default;

:root {
	--base-1: #039d55;
	--base-rgb: 3, 157, 85;

	--base-2: #00e77b;
	--base-3: #aaffd7;
	--base-4: #03c168;
	--base-5: #00a759;
	--base-6: #00b05e;
	--base-7: #00c066;
	--base-8: #6200ee;
	--base-9: #0079e3;
	--base-10: #ef7822;
	--base-11: #ffce31;
	--base-12: #039d55;
	--base-13: #e35606;
	--base-14: #45bd00;
	--base-15: #a9f97b;
	--base-16: #02d371;
	--base-17: #04f485;
	--base-18: #0dcb72;
	--base-19: #02da75;
	--base-20: #00be65;
	--base-21: #768d82;
	--base-22: 228, 255, 243;
	--base-23: #02bf67;
	--base-24: #03a057;
	--base-25: #01db75;
	--base-26: #04b061;
	--base-27: #22ff98;

	// Feature Card Colors

	--base-28: #54ed63;
	--base-29: #02b714;
	--base-30: #047bc7;
	--base-31: #7bcafd;

	--base-32: #a9f97b;
	--base-33: #45bd00;

	--base-34: #d3a2f5;
	--base-35: #8800e7;

	--base-36: #59f3ab;
	--base-37: #00bb63;

	--base-38: #ffc29f;
	--base-39: #e35606;

	--base-40: #9bffd0;

	--base-41: #85f390;
	--base-42: #02b714;
	--base-43: #17a1fa;
	--base-44: #61b2fa;
	--base-45: #f92e0f;
	--base-46: rgba(236, 175, 17, 0.9);

	--cloud-clr: #8cefb9;

	--header-bg: #f2f8f5;

	--body-clr: #717c77;
	--light-clr: #ded6ff;
	--title-clr: #2f3934;
	--title-rgb: 24, 25, 27;
	--body-bg: #ffffff;
	--section-bg: #fcfcfc;
	--white: #ffffff;
	--white-clr: #ffffff;
	--border-clr: #e5e5e5;
	--border-light: #e5e5e5;
	--heading-fonts: "Lato", sans-serif;
	--body-fonts: "Lato", sans-serif;
}
// 1
*[stroke="#039D55"] {
	stroke: var(--base-1);
}
*[fill="#039D55"] {
	fill: var(--base-1);
}
*[stop-color="#039D55"] {
	stop-color: var(--base-1);
}
// 1
*[stroke="#00E77B"] {
	stroke: var(--base-2);
}
*[fill="#00E77B"] {
	fill: var(--base-2);
}
*[stop-color="#00E77B"] {
	stop-color: var(--base-2);
}
// 1
*[stroke="#AAFFD7"] {
	stroke: var(--base-3);
}
*[fill="#AAFFD7"] {
	fill: var(--base-3);
}
*[stop-color="#AAFFD7"] {
	stop-color: var(--base-3);
}
// 1
*[stroke="#03C168"] {
	stroke: var(--base-4);
}
*[fill="#03C168"] {
	fill: var(--base-4);
}
*[stop-color="#03C168"] {
	stop-color: var(--base-4);
}
// 1
*[stroke="#00A759"] {
	stroke: var(--base-5);
}
*[fill="#00A759"] {
	fill: var(--base-5);
}
*[stop-color="#00A759"] {
	stop-color: var(--base-5);
}
// 1
*[stroke="#00B05E"] {
	stroke: var(--base-6);
}
*[fill="#00B05E"] {
	fill: var(--base-6);
}
*[stop-color="#00B05E"] {
	stop-color: var(--base-6);
}
// 1
*[stroke="#00C066"] {
	stroke: var(--base-7);
}
*[fill="#00C066"] {
	fill: var(--base-7);
}
*[stop-color="#00C066"] {
	stop-color: var(--base-7);
}
// 1
*[stroke="#6200EE"] {
	stroke: var(--base-8);
}
*[fill="#6200EE"] {
	fill: var(--base-8);
}
*[stop-color="#6200EE"] {
	stop-color: var(--base-8);
}
// 1
*[stroke="#0079E3"] {
	stroke: var(--base-9);
}
*[fill="#0079E3"] {
	fill: var(--base-9);
}
*[stop-color="#0079E3"] {
	stop-color: var(--base-9);
}
// 1
*[stroke="#EF7822"] {
	stroke: var(--base-10);
}
*[fill="#EF7822"] {
	fill: var(--base-10);
}
*[stop-color="#EF7822"] {
	stop-color: var(--base-10);
}
// 1
*[stroke="#FFCE31"] {
	stroke: var(--base-11);
}
*[fill="#FFCE31"] {
	fill: var(--base-11);
}
*[stop-color="#FFCE31"] {
	stop-color: var(--base-11);
}
// 1
*[stroke="#039D55"] {
	stroke: var(--base-12);
}
*[fill="#039D55"] {
	fill: var(--base-12);
}
*[stop-color="#039D55"] {
	stop-color: var(--base-12);
}
*[stroke="#E35606"] {
	stroke: var(--base-13);
}
*[fill="#E35606"] {
	fill: var(--base-13);
}
*[stop-color="#E35606"] {
	stop-color: var(--base-13);
}
*[stroke="#45BD00"] {
	stroke: var(--base-14);
}
*[fill="#45BD00"] {
	fill: var(--base-14);
}
*[stop-color="#45BD00"] {
	stop-color: var(--base-14);
}
*[stroke="#A9F97B"] {
	stroke: var(--base-15);
}
*[fill="#A9F97B"] {
	fill: var(--base-15);
}
*[stop-color="#A9F97B"] {
	stop-color: var(--base-15);
}
*[stroke="#02D371"] {
	stroke: var(--base-16);
}
*[fill="#02D371"] {
	fill: var(--base-16);
}
*[stop-color="#02D371"] {
	stop-color: var(--base-16);
}
*[stroke="#04F485"] {
	stroke: var(--base-17);
}
*[fill="#04F485"] {
	fill: var(--base-17);
}
*[stop-color="#04F485"] {
	stop-color: var(--base-17);
}
*[stroke="#0DCB72"] {
	stroke: var(--base-18);
}
*[fill="#0DCB72"] {
	fill: var(--base-18);
}
*[stop-color="#0DCB72"] {
	stop-color: var(--base-18);
}
*[stroke="#02DA75"] {
	stroke: var(--base-19);
}
*[fill="#02DA75"] {
	fill: var(--base-19);
}
*[stop-color="#02DA75"] {
	stop-color: var(--base-19);
}
*[stroke="#00BE65"] {
	stroke: var(--base-20);
}
*[fill="#00BE65"] {
	fill: var(--base-20);
}
*[stop-color="#00BE65"] {
	stop-color: var(--base-20);
}
*[stroke="#768D82"] {
	stroke: var(--base-21);
}
*[fill="#768D82"] {
	fill: var(--base-21);
}
*[stop-color="#768D82"] {
	stop-color: var(--base-21);
}
*[stroke="#02BF67"] {
	stroke: var(--base-23);
}
*[fill="#02BF67"] {
	fill: var(--base-23);
}
*[stop-color="#02BF67"] {
	stop-color: var(--base-23);
}
*[stroke="#03A057"] {
	stroke: var(--base-24);
}
*[fill="#03A057"] {
	fill: var(--base-24);
}
*[stop-color="#03A057"] {
	stop-color: var(--base-24);
}
*[stroke="#01DB75"] {
	stroke: var(--base-25);
}
*[fill="#01DB75"] {
	fill: var(--base-25);
}
*[stop-color="#01DB75"] {
	stop-color: var(--base-25);
}
*[stroke="#04B061"] {
	stroke: var(--base-26);
}
*[fill="#04B061"] {
	fill: var(--base-26);
}
*[stop-color="#04B061"] {
	stop-color: var(--base-26);
}
*[stroke="#22FF98"] {
	stroke: var(--base-27);
}
*[fill="#22FF98"] {
	fill: var(--base-27);
}
*[stop-color="#22FF98"] {
	stop-color: var(--base-27);
}
*[stroke="#9BFFD0"] {
	stroke: var(--base-40);
}
*[fill="#9BFFD0"] {
	fill: var(--base-40);
}
*[stop-color="#9BFFD0"] {
	stop-color: var(--base-40);
}
*[stroke="#85F390"] {
	stroke: var(--base-41);
}
*[fill="#85F390"] {
	fill: var(--base-41);
}
*[stop-color="#85F390"] {
	stop-color: var(--base-41);
}
*[stroke="#02B714"] {
	stroke: var(--base-42);
}
*[fill="#02B714"] {
	fill: var(--base-42);
}
*[stop-color="#02B714"] {
	stop-color: var(--base-42);
}
*[stroke="#8CEFB9"] {
	stroke: var(--cloud-clr);
}
*[fill="#8CEFB9"] {
	fill: var(--cloud-clr);
}
*[stop-color="#8CEFB9"] {
	stop-color: var(--cloud-clr);
}
@include breakpoint(xl) {
	.container {
		max-width: 1170px;
		padding-left: 30px;
		padding-right: 30px;
		&.max-w1060 {
			max-width: 1060px;
		}
	}
}
