.section-header {
	text-align: center;
	.title {
		margin-bottom: 12px;
	}
	margin-bottom: 35px;
	@include breakpoint(md) {
		.title {
			font-size: 30px;
			margin-bottom: 18px;
		}
		margin-bottom: 45px;
	}
}
.ecommerce-venture-section {
	background: linear-gradient(
		112.54deg,
		#ffffff 0%,
		rgba(var(--base-22), 0.541) 15.13%,
		rgba(255, 255, 255, 0.15) 79.34%,
		#ffffff 99.56%
	);
	padding: 53px 0px 68px;
}
.nav--tabs-wrapper {
	overflow-x: auto;
	padding-bottom: 5px;
}
.nav--tabs {
	border: none;
	gap: 10px;
	justify-content: space-between;
	min-width: 100%;
	width: auto;
	flex-wrap: nowrap;
	li {
		button {
			border: none;
			background: transparent;
			color: var(--base-23);
			width: 102px;
			padding-top: 21px;
			padding-bottom: 21px;
			border-radius: 10px;
			transition: all ease 0.3s;
			white-space: nowrap;
			outline: none;
			box-shadow: none;
			* {
				font-weight: 600;
			}
			&.active {
				animation: fadeIn 0.4s;
				-webkit-animation: fadeIn 0.4s;
				-moz-animation: fadeIn 0.4s;
				color: #ffffff;
				*[stroke="#00A95A"] {
					stroke: #ffffff;
				}
				*[fill="#00A95A"] {
					fill: #ffffff;
				}
				*[stop-color="#00A95A"] {
					stop-color: #ffffff;
				}
				*[stroke="#00C569"] {
					stroke: #ffffff;
				}
				*[fill="#00C569"] {
					fill: #ffffff;
				}
				*[stop-color="#00C569"] {
					stop-color: #ffffff;
				}
				background: linear-gradient(
					119.87deg,
					var(--base-5) 0%,
					var(--base-4) 40.8%,
					var(--base-6) 84.23%
				);
			}
			@include breakpoint(max-md) {
				min-width: 70px;
				padding-top: 14px;
				padding-bottom: 10px;
				padding-right: 10px;
				padding-left: 10px;
				width: unset;
				svg {
					width: 24px;
					height: 24px;
				}
				font-size: 14px;
			}
		}
	}
}
.venture-content {
	&-box {
		.subtitle {
			font-size: 20px;
			font-weight: 600;
		}
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 30px;
		@include breakpoint(max-md) {
			margin-bottom: 20px;
			.subtitle {
				font-size: 18px;
			}
			font-size: 16px;
		}
	}
}

.nav--tabs-wrapper {
	margin-bottom: 40px;
	@include breakpoint(lg) {
		margin-bottom: 44px;
	}
}
.tab-pane.active {
	animation: fadeInUp 0.6s;
	-webkit-animation: fadeInUp 0.6s;
	-moz-animation: fadeInUp 0.6s;
}
.venture-img {
	filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15));
	img {
		max-width: 100%;
	}
}
.pt-50 {
	padding-top: 50px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pt-30 {
	padding-top: 30px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pt-80 {
	padding-top: 80px;
}
.pb-80 {
	padding-bottom: 80px;
}
.main-category-slider {
	width: calc(100% - 30px);
	max-width: 787px;
	margin: 0 auto;
	.owl-stage-outer {
		overflow: visible;
		.owl-item {
			transition: all ease 0.3s;
			opacity: 0.17;
			&.active {
				opacity: 1;
			}
		}
	}
}
.category-slide-item {
	overflow: hidden;
	border-radius: 10px;
	padding: 25px 61px;
	display: flex;
	align-items: center;
	min-height: 287px;
	position: relative;
	&::after {
		position: absolute;
		content: "";
		inset: 0;
		background: linear-gradient(
			90deg,
			#b0f7d5 0%,
			rgba(196, 196, 196, 0) 91.23%
		);
	}
	div {
		position: relative;
		z-index: 1;
		max-width: 266px;
	}
	.title {
		color: var(--base-1);
		font-weight: 600;
		font-size: 49px;
		line-height: 35px;
		margin-bottom: 10px;
	}
	.text {
		font-weight: 600;
		font-size: 25px;
		line-height: 35px;
		color: #000000;
	}
	@include breakpoint(max-sm) {
		padding: 25px;
		min-height: 267px;
		.title {
			font-size: 42px;
		}
		.text {
			font-size: 20px;
		}
	}
}
.owl-dots {
	display: flex;
	justify-content: center;
	margin-top: 24px;
	gap: 6px;
	.owl-dot {
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background: #e4e4e4;
		transition: all ease 0.3s;
		&.active {
			background: rgba(var(--base-rgb), 0.5);
			width: 20px;
		}
	}
}
.refer-section {
	padding: 36px 0 65px;
}
.learn-feature-section {
	position: relative;
	overflow: hidden;
	&::before {
		content: "";
		inset: 0;
		position: absolute;
		background: rgba(#eefff7, 0.27);
	}
	@include breakpoint(max-lg) {
		.container {
			padding: 0;
		}
	}
}
.learn-feature-content {
	padding: 74px 0 120px;
	@include breakpoint(max-xl) {
		padding-bottom: 0;
		padding-left: 20px;
		padding-right: 20px;
	}
}
.learn-feature-item {
	.learn-feature-icon {
		width: 102px;
		height: 101px;
		background: linear-gradient(
			180deg,
			var(--base-24) 0%,
			var(--base-25) 100%
		);
		box-shadow: 0px 4px 40px rgba(3, 157, 85, 0.15);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 22px;
		transform: translateY(-34px);
		img {
			max-width: 100%;
		}
		@include breakpoint(max-sm) {
			width: 70px;
			height: 70px;
			svg {
				width: 24px;
			}
		}
	}
	text-align: center;
	.subtitle {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.05em;
		margin-bottom: 19px;
	}
	font-weight: 300;
	font-size: 16px;
	padding: 0 27px 32px;
	border: 1px solid var(--base-1);
	border-radius: 10px;
	background: var(--white-clr);
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
	margin-top: 30px;
	@include breakpoint(max-lg) {
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
	}
	.learn-feature-item-content {
		margin-top: -34px;
		.text {
			min-height: 52px;
		}
	}
	@include breakpoint(max-sm) {
		font-size: 14px;
		line-height: 1.5;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.learn-feature-item-group {
	div[class*="col"]:nth-child(odd) {
		div[class*="col"] {
			&:nth-of-type(4n + 2) {
				.learn-feature-item {
					.learn-feature-icon {
						background: linear-gradient(
							180deg,
							var(--base-10) 0%,
							var(--base-11) 100%
						);
						box-shadow: 0px 4px 40px rgba(255, 206, 49, 0.25);
					}
				}
			}
			&:nth-of-type(4n + 3) {
				.learn-feature-item {
					.learn-feature-icon {
						background: linear-gradient(
							180deg,
							var(--base-8) 0%,
							var(--base-9) 100%
						);
						box-shadow: 0px 4px 40px rgba(98, 0, 238, 0.25);
					}
				}
			}
			&:nth-of-type(4n + 4) {
				.learn-feature-item {
					.learn-feature-icon {
						background: linear-gradient(
							180deg,
							var(--base-45) 0%,
							var(--base-46) 100%
						);
						background: linear-gradient(
							180deg,
							var(--base-45) 0%,
							var(--base-46) 100%
						);
						box-shadow: 0px 4px 40px rgba(249, 62, 62, 0.25);
					}
				}
			}
		}
	}
	div[class*="col"]:nth-child(even) {
		div[class*="col"] {
			&:nth-of-type(4n + 1) {
				.learn-feature-item {
					.learn-feature-icon {
						background: linear-gradient(
							180deg,
							var(--base-8) 0%,
							var(--base-9) 100%
						);
						box-shadow: 0px 4px 40px rgba(98, 0, 238, 0.25);
					}
				}
			}
			&:nth-of-type(4n + 2) {
				.learn-feature-item {
					.learn-feature-icon {
						background: linear-gradient(
							180deg,
							var(--base-24) 0%,
							var(--base-25) 100%
						);
						box-shadow: 0px 4px 40px rgba(3, 157, 85, 0.15);
					}
				}
			}
			&:nth-of-type(4n + 3) {
				.learn-feature-item {
					.learn-feature-icon {
						background: linear-gradient(
							180deg,
							var(--base-10) 0%,
							var(--base-11) 100%
						);
						box-shadow: 0px 4px 40px rgba(255, 206, 49, 0.25);
					}
				}
			}
			&:nth-of-type(4n + 4) {
				.learn-feature-item {
					.learn-feature-icon {
						background: linear-gradient(
							180deg,
							var(--base-43) 0%,
							var(--base-44) 100%
						);
						box-shadow: 0px 4px 40px rgba(23, 161, 250, 0.25);
					}
				}
			}
		}
	}
	animation: scrollToTop 80s linear infinite;
}
.learn-feature-wrapper {
	height: 430px;
	overflow: visible;
	&::-webkit-scrollbar {
		display: none;
	}
	@include breakpoint(max-lg) {
		overflow: hidden;
		margin-left: 20px;
		margin-right: 20px;
	}
}
@keyframes scrollToTop {
	0% {
		@include transform(translateY(0));
	}
	100% {
		@include transform(translateY(calc(-100% + 422px)));
	}
}
.earn-money-section {
	padding: 62px 0 165px;
	position: relative;
	background: linear-gradient(
		112.54deg,
		#99f5ca51 0%,
		#ffffff 33.19%,
		#ffffff 66.37%,
		#1cff9518 99.56%
	);
	border-bottom-right-radius: 150px;
	overflow: hidden;
	.container-fluid {
		position: relative;
		z-index: 3;
	}
	@include breakpoint(max-lg) {
		padding-bottom: 80px;
	}
}
.earn-item {
	display: flex;
	align-items: center;
	&-img {
		width: 950px;
		flex-grow: 1;
		min-height: 264px;
		padding: 70px;
		border-radius: 0 50px 50px 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		overflow: hidden;
		&::before {
			position: absolute;
			content: "";
			inset: 0;
			background: linear-gradient(
				270deg,
				#195036 13.66%,
				rgba(2, 211, 113, 0) 89.75%
			);
			filter: drop-shadow(0px 4px 40px rgba(174, 255, 217, 0.25));
		}
		@include breakpoint(max-lg) {
			min-height: 230px;
		}
		@media (min-width: 1850px) {
			min-height: 280px;
		}
		@include breakpoint(max-md) {
			padding: 50px 30px;
		}
		@include breakpoint(max-sm) {
			max-width: calc(100% - 40px);
		}
		@media (max-width: 375px) {
			max-width: calc(100% - 20px);
			.cmn--btn {
				font-size: 14px;
			}
			min-height: 200px;
		}
	}
	&-cont {
		padding: 44px;
		width: 485px;
		flex-grow: 1;
		display: flex;
		.subtitle {
			font-size: 20px;
		}
		.title {
			font-weight: 800;
			font-size: 28px;
			color: var(--base-1);
			margin-top: 5px;
		}

		@include breakpoint(max-md) {
			padding: 30px;
		}
	}

	@include breakpoint(max-sm) {
		flex-wrap: wrap-reverse;
	}
	&:not(:last-child) {
		margin-bottom: 58px;
	}
	&:nth-child(even) {
		flex-direction: row-reverse;
		.earn-item-img {
			border-radius: 50px 0 0 50px;
			justify-content: flex-start;
			&::before {
				background: linear-gradient(
					-270deg,
					#195036 13.66%,
					rgba(2, 211, 113, 0) 89.75%
				);
			}
		}
		.earn-item-cont {
			justify-content: flex-end;
			@include breakpoint(xl) {
				> div {
					padding-right: 26px;
				}
			}
		}
	}
}
/*Earn Money Shapes*/
.e-shape-1 {
	position: absolute;
	top: -40px;
	right: 0;
	z-index: 1;
	@include breakpoint(max-lg) {
		opacity: 0.5;
	}
}
.e-shape-2 {
	position: absolute;
	top: 116px;
	left: 0;
	z-index: 1;
	@include breakpoint(max-lg) {
		opacity: 0.5;
	}
	@media (max-width: 350px) {
		opacity: 0;
	}
}
.e-shape-3 {
	position: absolute;
	bottom: 0;
	left: 0;
	height: unset;
	z-index: 0;
	opacity: 0.6;
	width: 70%;
	@include breakpoint(max-xl) {
		display: none;
	}
}
.e-shape-4 {
	position: absolute;
	bottom: 0;
	left: calc(50% - 100px);
	height: unset;
	z-index: 0;
	@include breakpoint(max-xl) {
		display: none;
	}
	opacity: 0.6;
}
.special-feature-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 36px;
	@include breakpoint(max-md) {
		gap: 20px;
	}
	@include breakpoint(md) {
		padding-bottom: 51px;
	}
}
.feature-card {
	border-radius: 5px;
	padding: 27px 27px 46px 52px;
	background: #f4fff5;
	width: calc((100% / 3) - 24px);
	&-icon {
		width: 60px;
		height: 60px;
		// background: linear-gradient(
		// 	180deg,
		// 	var(--base-28) 0%,
		// 	var(--base-29) 100%
		// );
		// border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
	}
	&-cont {
		margin-top: -10px;
		.subtitle {
			font-size: 22px;
			color: var(--base-28);
			font-weight: 700;
		}
	}
	&:nth-of-type(6n + 2) {
		background: rgba(232, 246, 255, 0.3);
		transform: translateY(51px);
		.feature-card-icon {
			// background: linear-gradient(
			// 	180deg,
			// 	var(--base-31) 0%,
			// 	var(--base-30) 100%
			// );
		}
		.feature-card-cont {
			.subtitle {
				color: var(--base-30);
			}
		}
	}
	&:nth-of-type(6n + 3) {
		background: #f1ffe9;
		.feature-card-icon {
			// background: linear-gradient(
			// 	180deg,
			// 	var(--base-32) 0%,
			// 	var(--base-33) 100%
			// );
		}
		.feature-card-cont {
			.subtitle {
				color: var(--base-33);
			}
		}
	}
	&:nth-of-type(6n + 4) {
		background: #f1ffe9;
		.feature-card-icon {
			// background: linear-gradient(
			// 	180deg,
			// 	var(--base-34) 0%,
			// 	var(--base-35) 100%
			// );
		}
		.feature-card-cont {
			.subtitle {
				color: var(--base-35);
			}
		}
	}
	&:nth-of-type(6n + 5) {
		background: #eefff7;
		transform: translateY(51px);
		// .feature-card-icon {
		// 	background: linear-gradient(
		// 		180deg,
		// 		var(--base-36) 0%,
		// 		var(--base-37) 100%
		// 	);
		// }
		.feature-card-cont {
			.subtitle {
				color: var(--base-37);
			}
		}
	}
	&:nth-of-type(6n + 6) {
		background: #fffaf7;
		// .feature-card-icon {
		// 	background: linear-gradient(
		// 		180deg,
		// 		var(--base-38) 0%,
		// 		var(--base-39) 100%
		// 	);
		// }
		.feature-card-cont {
			.subtitle {
				color: var(--base-39);
			}
		}
	}
	@include breakpoint(max-lg) {
		padding: 27px;
	}
	@include breakpoint(max-md) {
		padding: 20px;
		width: calc(50% - 20px);
		transform: translate(0) !important;
	}
	@include breakpoint(max-sm) {
		width: 100%;
		padding: 27px 35px 52px;
	}
}
.s-shape-1 {
	position: absolute;
	left: calc(50% + 500px);
	top: 30px;
}
.s-shape-2 {
	position: absolute;
	right: calc(50% + 500px);
	top: 30px;
}
.special-feature-section {
	.container {
		max-width: 915px;
	}
}
.testimonial-section {
	padding: 40px 0 136px;
	.container {
		position: relative;
		z-index: 2;
	}
}
.testimonial-shape {
	position: absolute;
	width: 1362px;
	left: 50%;
	transform: translateX(-50%);
	top: 70px;
}
.testimonial-item {
	box-shadow: 0px 1px 120px rgba(163, 186, 164, 0.41);
	border-radius: 15px;
	padding: 42px;
	background: var(--white-clr);
	.user {
		display: flex;
		align-items: center;
		img {
			width: 40px;
			aspect-ratio: 1;
			border-radius: 50%;
			object-fit: cover;
		}
		div {
			padding-left: 10px;
			.name {
				font-size: 14px;
				line-height: 1.3;
				font-weight: 700;
				margin-bottom: -5px;
			}
			font-size: 12px;
		}
	}
	.logo {
		max-width: 102px;
	}
	blockquote {
		margin-top: 15px;
	}
	@include breakpoint(max-xl) {
		padding: 35px 25px;
		.logo {
			width: 80px;
		}
	}
}
.testimonial-slider {
	position: relative;
	.owl-stage-outer {
		overflow: visible;
	}
}
.download-app-section {
	background: linear-gradient(180deg, #e7fff4 0%, #84fdc400 100%);
	padding-top: 218px;
	@include breakpoint(max-md) {
		padding-top: 178px;
	}
}
.download-app-content {
	padding-top: 35px;
	.title {
		color: var(--base-1);
		font-size: 36px;
		line-height: 1.3;
		font-weight: 800;
	}
	.subtitle {
		font-size: 30px;
		line-height: 1.3;
		color: var(--base-16);
	}
	.btn-grp {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 16px;
		margin-top: 46px;
		.cmn--btn {
			font-size: 16px;
		}
		@include breakpoint(max-lg) {
			margin-top: 35px;
		}
		@include breakpoint(max-sm) {
			gap: 10px;
			.cmn--btn {
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
	@include breakpoint(max-sm) {
	}
}
/*Counter Section*/
.counter-wrapper {
	padding: 40px 0 95px 25px;
	.counter-bg {
		border: 1px solid #c6fde3;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: #ffffff;
		@include breakpoint(lg) {
			mask: url(./img/counter-shape.png) no-repeat;
			-webkit-mask: url(./img/counter-shape.png) no-repeat;
			mask-size: 100% 100%;
			-webkit-mask-size: 100% 100%;
		}
		border-radius: 45px;
	}
	filter: drop-shadow(0px 4px 40px rgba(0, 138, 73, 0.15));
	-webkit-filter: drop-shadow(0px 4px 40px rgba(0, 138, 73, 0.15));
	.contact-wrapper-inner {
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.left-side {
			flex-grow: 1;
			width: 420px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			gap: 25px;
		}
		.right-side {
			padding: 27px 28px;
			background: #f1fff9;
			border: 1px solid #8ef1c3;
			box-shadow: 0px 4px 40px rgba(52, 206, 134, 0.12);
			border-radius: 27px;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: var(--base-1);
			transform: translateX(30px);
			img {
				width: 27px;
				margin-right: 15px;
			}
		}
	}
	@include breakpoint(max-md) {
		padding: 40px 20px;
		.right-side {
			transform: translate(0);
			margin-left: auto;
			margin-top: 20px;
			margin-right: 30px;
			padding: 15px 22px !important;
			font-size: 16px !important;
			border-radius: 14px !important;
		}
	}
}
.mb--186px {
	margin-bottom: -161px;
}
.word-nowrap {
	white-space: nowrap;
}
.counter-item {
	color: #cfcfcf;
	font-size: 22px;
	line-height: 43px;
	text-align: center;
	.icon {
		display: flex;
		justify-content: center;
		margin-bottom: 7px;
	}
	.title {
		font-weight: 800;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: lowercase;
		margin-bottom: -9px;
		white-space: nowrap;
		.odometer-inside {
			display: flex;
			align-items: center;
		}
		span {
			font-size: 30px;
			line-height: 43px;
			display: block;
		}
	}
	@include breakpoint(lg) {
		&:first-child {
			transform: translateY(30px);
		}
		&:nth-child(2) {
			transform: translateY(20px);
		}
		&:nth-child(3) {
			transform: translateY(10px);
		}
		&:nth-child(4) {
			.icon {
				margin-bottom: 2px;
			}
		}
	}
	@include breakpoint(max-lg) {
		width: calc(50% - 12.5px);
		.icon {
			height: 30px;
		}
	}
	@include breakpoint(max-sm) {
		font-size: 18px;
	}
}
/*About Section*/
.about-section {
	position: relative;
	background: linear-gradient(
		112.54deg,
		#ffffff 0%,
		rgba(228, 255, 243, 0.54) 15.13%,
		rgba(255, 255, 255, 0.15) 79.34%,
		#ffffff 99.56%
	);
	.left--shape {
		position: absolute;
		left: 0;
		top: 0;
	}
	padding: 60px 0;
	@include breakpoint(lg) {
		padding: 80px 0;
	}
	.container {
		position: relative;
		z-index: 9;
	}
}
.about-content {
	p:not(:last-child) {
		margin-bottom: 30px;
	}
	@include breakpoint(lg) {
		font-size: 18px;
		font-weight: 500;
	}
}
.privacy-wrapper {
	background: var(--body-bg);
	box-shadow: 0px 4px 20px rgba(4, 97, 165, 0.1);
	border-radius: 30px;
	padding: 45px 50px;
	@include breakpoint(max-md) {
		padding: 40px 30px;
	}
	@include breakpoint(max-sm) {
		padding: 30px 15px;
	}
	.item {
		.subtitle {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		p {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		&:not(:last-child) {
			margin-bottom: 35px;
		}
	}
}
.contact-img {
	position: relative;
	@include border-radius(30px);
	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		inset: 0;
		background: linear-gradient(
			270deg,
			#195036 13.66%,
			rgba(2, 211, 113, 0) 89.75%
		);
		transform: matrix(-1, 0, 0, 1, 0, 0);
	}
	img {
		width: 100%;
		height: 129px;
		object-fit: cover;
	}
}
.contact-form-wrapper {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	border-radius: 30px;
	padding: 30px 28px;
	height: 100%;
	@include breakpoint(max-sm) {
		padding: 25px 23px;
	}
}
.form--control {
	background: #e0fff1;
	border-radius: 30px;
	border: none;
	box-shadow: none !important;
	color: #63d9a2;
	padding: 20px;
	&:focus {
		background: #e0fff1;
	}
}
input.form--control {
	height: 47px;
}
textarea.form--control {
	height: 120px;
}
.contact-item {
	padding: 40px 24px 28px;
	.subtitle {
		font-size: 20px;
	}
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	border-radius: 30px;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	color: #18191b;
	ul {
		margin-top: 15px;
		li {
			a {
				color: #18191b;
				text-decoration: none;
			}
		}
	}
}
.contact-container {
	@include breakpoint(xl) {
		max-width: 1298px;
	}
}
.refer-img {
	position: relative;
	max-width: 518px;
	margin: 0 auto;
	.shape-1,
	.shape-2,
	.shape-3,
	.shape-4,
	.shape-5,
	.shape-6 {
		position: absolute;
	}
	.shape-1 {
		left: 60.31px;
		bottom: 24.67px;
		animation: shake-1 2s linear infinite;
	}
	.shape-2 {
		left: 60.07px;
		top: 71.2px;
		animation: shake-1 2s linear infinite;
	}
	.shape-3 {
		bottom: 55.9px;
		right: 66.03px;
		animation: shake-2 2s linear infinite;
	}
	.shape-4 {
		top: 69.27px;
		left: 181px;
		animation: pulse 2s alternate infinite;
	}
	.shape-5 {
		bottom: 170.47px;
		right: 137.03px;
		animation: pulse 2s alternate infinite;
	}
	.shape-6 {
		top: 117px;
		left: 180px;
		animation: shake-3 2s linear infinite;
	}
	.main-img {
		max-width: 518px;
	}
	@include breakpoint(max-sm) {
		max-width: 299px;
		.shape-1 {
			left: 25.31px;
			bottom: -14.33px;
			width: 77px;
		}
		.shape-2 {
			left: 25px;
			top: 12.2px;
			width: 70px;
		}
		.shape-3 {
			bottom: -26px;
			right: 26.03px;
			width: 70px;
		}
		.shape-4 {
			top: 24.269999999999996px;
			left: 106px;
		}
		.shape-5 {
			top: 128.27px;
			left: 199px;
			width: 23px;
		}
		.shape-4 {
			top: 24.269999999999996px;
			left: 106px;
		}
		.shape-6 {
			top: 59px;
			left: 102px;
			width: 30px;
		}
	}
}
@keyframes shake-1 {
	0% {
		transform: rotate(0);
	}
	10% {
		transform: rotate(0);
	}
	40% {
		transform: rotate(-15deg);
	}
	60% {
		transform: rotate(-15deg);
	}
	90% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}
@keyframes shake-2 {
	0% {
		transform: rotate(0);
	}
	10% {
		transform: rotate(0);
	}
	40% {
		transform: rotate(12deg);
	}
	60% {
		transform: rotate(12deg);
	}
	90% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}
@keyframes shake-3 {
	0% {
		transform: rotate(0);
	}
	10% {
		transform: rotate(0);
	}
	40% {
		transform: rotate(-8deg);
	}
	60% {
		transform: rotate(-8deg);
	}
	90% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}

#landing-loader {
	background: var(--white);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
}
