.cmn--btn {
	font-size: 16px;
	padding: 12px 16px;
	min-width: 129px;
	line-height: 25px;
	font-weight: 700;
	text-decoration: none;
	color: var(--white-clr);
	background: var(--base-1);
	border-radius: 10px;
	text-align: center;
	background: linear-gradient(
		119.87deg,
		var(--base-5) 0%,
		var(--base-4) 40.8%,
		var(--base-6) 84.23%
	);
	box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.1);
	transition: all ease 0.3s;
	&:hover {
		color: var(--white-clr);
		filter: brightness(0.9);
	}
}
