.newsletter-content {
	max-width: 450px;
	text-align: center;
	margin: 0 auto;
	padding: 138px 15px 51px;
	z-index: 1;
	position: relative;
	z-index: 99;
	.title {
		font-size: 24px;
		letter-spacing: 0.2px;
		margin-bottom: 20px;
		position: relative;
		&::after {
			content: "";
			background: linear-gradient(
				90.07deg,
				var(--base-25) -2.32%,
				var(--base-27) 99.95%
			);
			border-radius: 10px;
			width: 118px;
			height: 7px;
			bottom: 0;
			display: block;
			margin: 15px auto 0;
		}
	}
	.text {
		max-width: 373px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
		font-size: 18px;
		font-weight: 500;
	}
	@include breakpoint(max-md) {
		padding-top: 80px;
	}
	@include breakpoint(max-sm) {
		padding-top: 60px;
		font-size: 15px;
		.title {
			font-size: 20px;
		}
	}
	@include breakpoint(max-lg) {
		@include breakpoint(md) {
			padding-top: 115px;
		}
	}
	@include breakpoint(max-md) {
		padding-top: 134px;
	}
	@include breakpoint(max-sm) {
		padding-top: 115px;
	}
}
.input--grp {
	position: relative;
	.form-control {
		height: 60px;
		box-shadow: none;
		border-radius: 30px;
		padding: 0 60px 0 25px;
		border: none;
		font-size: 18px;
		background: #e0fff1;
		&::placeholder {
			color: #adb9c0;
		}
	}
	.search-btn {
		position: absolute;
		outline: none;
		border: none;
		background: transparent;
		box-shadow: none;
		top: 6px;
		right: 3px;
		position: absolute;
	}
}
.newsletter-bg {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: #fff4d7;
	mask: url(./img/newsletter-bg.png) no-repeat;
	-webkit-mask: url(./img/newsletter-bg.png) no-repeat;
	mask-size: 100% 100%;
	-webkit-mask-size: 100% 100%;
}
.footer-bottom {
	background: linear-gradient(180deg, var(--base-2) 0%, var(--base-1) 100%);
	padding-top: 30px;
	padding-bottom: 30px;
	border-radius: 60px 60px 0 0;
	position: relative;
}
.newsletter-section {
	position: relative;
}
.copyright {
	font-size: 12px;
	line-height: 22px;
	color: var(--white);
}
.footer-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	justify-content: space-between;
	@include breakpoint(max-lg) {
		margin-bottom: 35px;
	}
}
.footer-widget {
	font-size: 16px;
	color: var(--white);
	width: 100%;
	@include breakpoint(lg) {
		max-width: 349px;
	}
	.footer-logo {
		width: 100%;
		max-width: 134px;
		margin-bottom: 18px;
		a {
			display: block;
		}
		img {
			width: 100%;
		}
	}
	.subtitle {
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 20px;
		@include breakpoint(max-sm) {
			margin-bottom: 15px;
		}
	}
	&.widget-links {
		width: calc(50% - 15px);
		max-width: 221px;
		ul {
			li {
				a {
					i,
					svg {
						margin-right: 5px;
					}
					font-size: 15px;
					color: var(--white);
					text-decoration: none;
					@include breakpoint(max-sm) {
						font-size: 14px;
					}
				}
			}
		}
	}
}
.social-icon {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	margin-top: 15px;
	@include breakpoint(max-sm) {
		gap: 20px;
	}
	margin-bottom: 15px;
}
.app-btn-grp {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	a {
		max-width: calc(50% - 8px);
		display: block;
		img {
			max-width: 100%;
		}
	}
}
footer {
	overflow: hidden;
}
.newsletter-wrapper {
	position: relative;
	max-width: 932px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin: 0 auto;
	&::after {
		inset: 70px;
		background: var(--white-clr);
		@include breakpoint(max-lg) {
			@include breakpoint(md) {
				inset: 50px;
			}
		}
		@include breakpoint(max-md) {
			width: 640px;
		}
	}
	&::before {
		background: radial-gradient(
			54.67% 54.67% at 50% -10.09%,
			rgba(2, 218, 117, 0.62) 0%,
			#00be65 100%
		);
		inset: 0;
		@include breakpoint(max-md) {
			width: 700px;
		}
	}
	&::after,
	&::before {
		position: absolute;
		content: "";
		aspect-ratio: 1;
		@include border-radius(50%);
		@include breakpoint(max-md) {
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
