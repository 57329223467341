@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@import "_abstracts/_mixins";
@import "_abstracts/_variables";
@import "_base/_typography";
@import "_base/_about";
@import "_layout/_footer";
@import "_layout/_forms";
@import "_layout/_header";
@import "_layout/_banner";
@import "_layout/_sidebar";
@import "_layout/_section";
@import "_layout/_animation";
@import "_layout/_blog";
@import "_components/_buttons";
@import "_components/_color";
.dropdown-list {
	position: absolute;
	top: calc(100% + 10px);
	right: 0;
	padding: 10px 0;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(141, 153, 174, 0.5);
	transform: translateY(15px);
	visibility: hidden;
	opacity: 0;
	transition: all ease 0.4s;
	background: var(--white-clr);
	z-index: 99;
	width: 100%;
	min-width: 200px;
}

.dropdown-list li a {
	color: var(--title-clr);
	text-transform: capitalize;
	font-size: 14px;
	padding: 4px 25px;
	text-decoration: none;
	display: block;
	border-inline-start: 3px solid transparent;
}

.dropdown-list li a:hover {
	color: var(--base-1);
	border-color: var(--base-1);
}

.dropdown--btn:focus ~ .dropdown-list {
	transform: translateY(0);
	visibility: visible;
	opacity: 1;
}
