header {
	position: sticky;
	z-index: 999;
	transition: all ease 0.3s;
	&.active {
		background: var(--header-bg);
		animation: fadeInDown 0.5s;
		top: 0;
	}
}
.nav-toggle {
	position: relative;
	cursor: pointer;
	width: 25px;
	height: 20px;
	span {
		position: absolute;
		display: inline-block;
		height: 2px;
		width: 100%;
		-webkit-transition: all ease 0.3s;
		-moz-transition: all ease 0.3s;
		transition: all ease 0.3s;
		background: var(--title-clr);
		left: 0;
		&:first-child {
			top: 0;
		}
		&:nth-child(2) {
			top: 52%;
			transform: translateY(-65%);
		}
		&:last-child {
			bottom: 0;
		}
	}
	&.active span {
		&:first-child {
			-webkit-transform: rotate(45deg) translate(3px, 10px);
			-ms-transform: rotate(45deg) translate(3px, 10px);
			transform: rotate(45deg) translate(3px, 10px);
		}
		&:nth-child(2) {
			opacity: 0;
		}
		&:last-child {
			-webkit-transform: rotate(-45deg) translate(3px, -10px);
			-ms-transform: rotate(-45deg) translate(3px, -10px);
			transform: rotate(-45deg) translate(3px, -10px);
		}
	}
}
.navbar-bottom-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 22px 17px;
	.menu {
		display: flex;
		margin-left: auto;
		transition: all ease 0.3s;
		transform-origin: top;
		li {
			padding: 2.5px 10px;
			a {
				padding: 10px;
				font-size: 16px;
				color: var(--title-clr);
				text-transform: capitalize;
				text-decoration: none;
				span {
					position: relative;
					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: -9px;
						height: 2px;
						background: var(--base-1);
						border-radius: 2px;
						width: 0;
						transition: 0.3s ease-in-out;
					}
				}
				&:hover,
				&.active {
					color: var(--base-1);
					span::before,
					span::before {
						width: 100%;
					}
				}
				&.active {
					font-weight: 700;
				}
			}
		}
		@media (max-width: 991.9px) {
			position: absolute;
			top: 100%;
			flex-direction: column;
			width: 100%;
			padding: 30px 20px;
			background: var(--header-bg);
			left: 0;
			border-top: 1px solid var(--border-clr);
			li {
				padding: 0;
				a {
					padding: 5px;
					display: block;
				}
			}
			&:not(.active) {
				transform: scaleY(0);
			}
		}
	}
	.logo {
		height: 50px;
		max-width: 220px;
		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
			object-position: left;
		}
	}
	@media (max-width: 575px) {
		padding: 10px;
		.logo {
			width: unset;
			// flex-grow: 1;
			margin-right: 10px;
			max-width: 130px;
		}
		.cmn--btn {
			font-size: 12px;
			padding: 3px 12px;
			font-weight: 400;
		}
	}
}
.language-bar {
	color: var(--title-clr);
	button {
		outline: none;
		border: none;
		box-shadow: none;
		background: transparent;
		font-size: 15px;
		display: flex;
		align-items: center;
		img {
			width: 18px;
			height: 13px;
			object-fit: cover;
		}
		span {
			// padding-left: 7px;
			color: var(--title-clr);
			margin-right: 6px;
		}
	}
	position: relative;
	.lang-btn {
		padding: 3px 0;
		margin: 0;
		height: 20px;
		margin-right: 30px;
		@include breakpoint(max-sm) {
			margin-right: 15px;
			font-size: 14px;
		}
	}
	ul {
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		padding: 10px 0;
		border-radius: 5px;
		box-shadow: 0 0 5px rgba(141, 153, 174, 0.5);
		transform: translateY(15px);
		visibility: hidden;
		opacity: 0;
		transition: all ease 0.4s;
		background: var(--white-clr);
		z-index: 99;
		li {
			padding: 0;
			button {
				height: 35px;
				padding: 0 23px 0 23px;
				margin: 0;
				span {
					color: var(--dark-clr);
					font-size: 14px;
				}
			}
		}
	}
	> button:focus ~ ul {
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
	}
}
.header--btn {
	border: 1px solid var(--base-12);
	border-radius: 10px;
	padding: 5px 22px;
	font-size: 16px;
	color: var(--title-clr);
	text-decoration: none;
	@include breakpoint(max-sm) {
		font-size: 14px;
		padding: 3px 13px;
	}
}
.h-18px {
	@include breakpoint(lg) {
		height: 18px;
	}
}
