.bg--body {
	background-color: var(--body-bg) !important;
}
.bg--section {
	background-color: var(--section-bg) !important;
}

.text--body {
	color: var(--body-clr) !important;
}
.text--base {
	color: var(--base-1) !important;
}

.text--title {
	color: var(--title-clr) !important;
}
